html,
body {
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#basic-nav-dropdown{
  position: relative;
  color: rgb(102, 101, 101);
/* margin-left: 10px; */
/* margin-right: 10px; */
  padding: 5px; 
}

.dropdown-left-manual {
  right: 0;
  left: auto;
  padding-left: 1px;
  padding-right: 1px;
}

.highlight:hover{
  background-color:#e9e9e9;
  border-radius: 120px;
  padding: 1px;
  border: 2px;
}

.footer{
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color:rgb(224, 224, 224);
  margin-bottom: 0px;
  flex-shrink: 0;
}

.leftCenter2{
  position: relative;
  /* margin-right: 65%; */
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  letter-spacing: 5px;
  font-size:20px;
  word-wrap: break-word;
  /* transform: translate(40%, -800%); */
  /* margin-top: -10%; */
  /* margin-bottom:20px; */
  background-color:black;
  color: white;
}

.leftCenter{
  position: relative;
  /* margin-right: 55%; */
  /* margin-top: 4px; */
  text-align: center;
  font-family: "Times New Roman";
  letter-spacing: 6px;
  padding: 3px 4px;
  font-size:60px;
  /* transform: translate(31%, -370%); */
  background-color:white;
  color: black;
}


.ApplyNow{
  position: relative;
  padding: 10px 35px;
  border-radius: 5px;
  font-size: 20px;
  margin-bottom: 10px;
  font-family: sans-serif;
  background-color: rgb(212, 212, 195);
  text-decoration: none;
  color: #000;
  border: none;
  box-shadow: 0 5px #999;

  &:hover{
    background-color: rgb(212, 212, 195);
    color: black;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
}

.content {
  flex: 1 0 auto;
}

.contactNo:hover, .contactNo{
  position: relative;
  /* margin-right: 43%; */
  /* margin-top: 10px; */
  /* transform: translate(-115%, -650%); */
  padding: 10px 35px;
  font-size: 20px;
  font-family: sans-serif;
  background-color: rgb(212, 212, 195);
  text-decoration: none;
  color: #000;
  border: none;
  box-shadow: 0 5px #999;
}

.aboutus{
  overflow: hidden;
  position: relative;
}
.topicHeading{
  display: table;
  background-color: rgb(231, 231, 231);
  color: black;
  width: auto;
  font-family: sans-serif;
  margin: 10px auto;
  justify-content: center;
  text-align: center;
  letter-spacing: 4px;
  /* border-radius: 10px; */
  padding: 10px 40px;
  /* border: 1px solid gold; */
}

.aboutHeading{
  display: table;
  background-color: black;
  color: white;
  width: auto;
  font-family: sans-serif;
  margin: 10px auto;
  justify-content: center;
  text-align: center;
  letter-spacing: 4px;
  /* border-radius: 10px; */
  padding: 15px 30px;
  /* border: 1px solid gold; */
}

.DescriptionHeading{
  margin: 10px auto;
  display: table;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding-bottom: 20px;
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  box-shadow: 0 1px rgb(190, 190, 190);
}



.home{
  position: relative;
  align-items: center;
  width: 100%;
  /* height: 720px; */
  /* margin:0 auto; */
  /* overflow: hidden; */
  /* background-color: blue; */
  /* background-image: url(../src/images/school1.jpg); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
}

.description{
  margin: 40px 0px;
  margin-left:80px;
  margin-right:80px;
}

.Navbar {
  width: 100%;
}

.Nav {
  width: auto;
  margin-right: auto;
}